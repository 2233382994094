import React from "react"
import Racepage from "../components/racepage"

const Besseges2021 = () => {
  const startlist = [
    {
      number: " 217",
      lastname: " LAMAILLE",
      firstname: " Christopher",
      team: " Cambodia Cycling Academy",
    },
    {
      number: " 214",
      firstname: " Barry",
      lastname: " MILLER",
      team: " Cambodia Cycling Academy",
    },
    {
      number: " 213",
      firstname: "Antoine",
      lastname: "BERLIN",
      team: " Cambodia Cycling Academy",
    },
    {
      number: " 212",
      name: " LE BON Johan",
      firstname: "Johan",
      lastname: "LE BON",
      team: " Cambodia Cycling Academy",
    },
    {
      number: " 211",
      name: " AURIGNAC Samy",
      firstname: "Samy",
      lastname: "AURIGNAC",
      team: " Cambodia Cycling Academy",
    },
    {
      number: " 207",
      name: " LEVASSEUR Jordan",
      firstname: "Jordan",
      lastname: "LEVASSEUR",
      team: " Xelliss - Roubaix Lille Métropole",
    },
    {
      number: " 206",
      name: " TABELLION Valentin",
      firstname: "Valentin",
      lastname: "TABELLION",
      team: " Xelliss - Roubaix Lille Métropole",
    },
    {
      number: " 205",
      name: " PICOUX Maximilien",
      firstname: "Maximilien",
      lastname: "PICOUX",
      team: " Xelliss - Roubaix Lille Métropole",
    },
    {
      number: " 204",
      name: " LEROUX Samuel",
      firstname: "Samuel",
      lastname: "LEROUX",
      team: " Xelliss - Roubaix Lille Métropole",
    },
    {
      number: " 203",
      name: " KOWALSKI Dylan",
      firstname: "Dylan",
      lastname: "KOWALSKI",
      team: " Xelliss - Roubaix Lille Métropole",
    },
    {
      number: " 202",
      name: " CENTRONE Ivan",
      firstname: "Ivan",
      lastname: "CENTRONE",
      team: " Xelliss - Roubaix Lille Métropole",
    },
    {
      number: " 201",
      name: " VERMEULEN Emiel",
      firstname: "Emiel",
      lastname: "VERMEULEN",
      team: " Xelliss - Roubaix Lille Métropole",
    },
    {
      number: " 197",
      name: " GARCIA Raul",
      firstname: "Raul",
      lastname: "GARCIA",
      team: " Equipo Kern Pharma",
    },
    {
      number: " 196",
      name: " ŘEPA Vojtěch",
      firstname: "Vojtěch",
      lastname: "ŘEPA",
      team: " Equipo Kern Pharma",
    },
    {
      number: " 195",
      name: " BERRADE Urko",
      firstname: "Urko",
      lastname: "BERRADE",
      team: " Equipo Kern Pharma",
    },
    {
      number: " 194",
      name: " ADRIÀ Roger",
      firstname: "Roger",
      lastname: "ADRIÀ",
      team: " Equipo Kern Pharma",
    },
    {
      number: " 193",
      name: " SANZ Enrique",
      firstname: "Enrique",
      lastname: "SANZ",
      team: " Equipo Kern Pharma",
    },
    {
      number: " 192",
      name: " GALVÁN Francisco",
      firstname: "Francisco",
      lastname: "GALVÁN",
      team: " Equipo Kern Pharma",
    },
    {
      number: " 191",
      name: " MARQUEZ Marti",
      firstname: "Marti",
      lastname: "MARQUEZ",
      team: " Equipo Kern Pharma",
    },
    {
      number: " 187",
      name: " VAN NIEKERK Morne",
      firstname: "Morne",
      lastname: "VAN NIEKERK",
      team: " St Michel - Auber93",
    },
    {
      number: " 186",
      name: " TESSON Jason",
      firstname: "Jason",
      lastname: "TESSON",
      team: " St Michel - Auber93",
    },
    {
      number: " 185",
      name: " PAILLOT Yoann",
      firstname: "Yoann",
      lastname: "PAILLOT",
      team: " St Michel - Auber93",
    },
    {
      number: " 184",
      name: " MAURELET Flavien",
      firstname: "Flavien",
      lastname: "MAURELET",
      team: " St Michel - Auber93",
    },
    {
      number: " 183",
      name: " LOUVET Louis",
      firstname: "Louis",
      lastname: "LOUVET",
      team: " St Michel - Auber93",
    },
    {
      number: " 182",
      name: " HUREL Tony",
      firstname: "Tony",
      lastname: "HUREL",
      team: " St Michel - Auber93",
    },
    {
      number: " 181",
      name: " CARDIS Romain",
      firstname: "Romain",
      lastname: "CARDIS",
      team: " St Michel - Auber93",
    },
    {
      number: " 177",
      name: " VALLÉE Boris",
      firstname: "Boris",
      lastname: "VALLÉE",
      team: " Bingoal - Wallonie Bruxelles",
    },
    {
      number: " 176",
      name: " SUTER Joel",
      firstname: "Joel",
      lastname: "SUTER",
      team: " Bingoal - Wallonie Bruxelles",
    },
    {
      number: " 175",
      name: " ROBEET Ludovic",
      firstname: "Ludovic",
      lastname: "ROBEET",
      team: " Bingoal - Wallonie Bruxelles",
    },
    {
      number: " 174",
      name: " PAQUOT Tom",
      firstname: "Tom",
      lastname: "PAQUOT",
      team: " Bingoal - Wallonie Bruxelles",
    },
    {
      number: " 173",
      name: " MENTEN Milan",
      firstname: "Milan",
      lastname: "MENTEN",
      team: " Bingoal - Wallonie Bruxelles",
    },
    {
      number: " 172",
      name: " DUPONT Timothy",
      firstname: "Timothy",
      lastname: "DUPONT",
      team: " Bingoal - Wallonie Bruxelles",
    },
    {
      number: " 171",
      name: " LIVYNS Arjen",
      firstname: "Arjen",
      lastname: "LIVYNS",
      team: " Bingoal - Wallonie Bruxelles",
    },
    {
      number: " 167",
      name: " JENSEN August",
      firstname: "August",
      lastname: "JENSEN",
      team: " DELKO",
    },
    {
      number: " 166",
      name: " ŠIŠKEVIČIUS Evaldas",
      firstname: "Evaldas",
      lastname: "ŠIŠKEVIČIUS",
      team: " DELKO",
    },
    {
      number: " 165",
      name: " GROSU Eduard-Michael",
      firstname: "Eduard-Michael",
      lastname: "GROSU",
      team: " DELKO",
    },
    {
      number: " 164",
      name: " FINETTO Mauro",
      firstname: "Mauro",
      lastname: "FINETTO",
      team: " DELKO",
    },
    {
      number: " 163",
      name: " DELETTRE Alexandre",
      firstname: "Alexandre",
      lastname: "DELETTRE",
      team: " DELKO",
    },
    {
      number: " 162",
      name: " CARISEY Clément",
      firstname: "Clément",
      lastname: "CARISEY",
      team: " DELKO",
    },
    {
      number: " 161",
      name: " BARBIER Pierre",
      firstname: "Pierre",
      lastname: "BARBIER",
      team: " DELKO",
    },
    {
      number: " 157",
      name: " WILLEMS Thimo",
      firstname: "Thimo",
      lastname: "WILLEMS",
      team: " Sport Vlaanderen - Baloise",
    },
    {
      number: " 156",
      name: " WEEMAES Sasha",
      firstname: "Sasha",
      lastname: "WEEMAES",
      team: " Sport Vlaanderen - Baloise",
    },
    {
      number: " 155",
      name: " VAN ROOY Kenneth",
      firstname: "Kenneth",
      lastname: "VAN ROOY",
      team: " Sport Vlaanderen - Baloise",
    },
    {
      number: " 154",
      name: " VAN DEN BOSSCHE Fabio",
      firstname: "Fabio",
      lastname: "VAN DEN BOSSCHE",
      team: " Sport Vlaanderen - Baloise",
    },
    {
      number: " 153",
      name: " REYNDERS Jens",
      firstname: "Jens",
      lastname: "REYNDERS",
      team: " Sport Vlaanderen - Baloise",
    },
    {
      number: " 152",
      name: " HERREGODTS Rune",
      firstname: "Rune",
      lastname: "HERREGODTS",
      team: " Sport Vlaanderen - Baloise",
    },
    {
      number: " 151",
      name: " DE VYLDER Lindsay",
      firstname: "Lindsay",
      lastname: "DE VYLDER",
      team: " Sport Vlaanderen - Baloise",
    },
    {
      number: " 147",
      name: " VERGAERDE Otto",
      firstname: "Otto",
      lastname: "VERGAERDE",
      team: " Alpecin-Fenix",
    },
    {
      number: " 146",
      name: " DE BONDT Dries",
      firstname: "Dries",
      lastname: "DE BONDT",
      team: " Alpecin-Fenix",
    },
    {
      number: " 145",
      name: " LEYSEN Senne",
      firstname: "Senne",
      lastname: "LEYSEN",
      team: " Alpecin-Fenix",
    },
    {
      number: " 144",
      name: " GAZE Samuel",
      firstname: "Samuel",
      lastname: "GAZE",
      team: " Alpecin-Fenix",
    },
    {
      number: " 143",
      name: " MERLIER Tim",
      firstname: "Tim",
      lastname: "MERLIER",
      team: " Alpecin-Fenix",
    },
    {
      number: " 142",
      name: " BAYER Tobias",
      firstname: "Tobias",
      lastname: "BAYER",
      team: " Alpecin-Fenix",
    },
    {
      number: " 141",
      name: " DILLIER Silvan",
      firstname: "Silvan",
      lastname: "DILLIER",
      team: " Alpecin-Fenix",
    },
    {
      number: " 137",
      name: " REZA Kévin",
      firstname: "Kévin",
      lastname: "REZA",
      team: " B&B Hotels p/b KTM",
    },
    {
      number: " 136",
      name: " PACHER Quentin",
      firstname: "Quentin",
      lastname: "PACHER",
      team: " B&B Hotels p/b KTM",
    },
    {
      number: " 135",
      name: " LEMOINE Cyril",
      firstname: "Cyril",
      lastname: "LEMOINE",
      team: " B&B Hotels p/b KTM",
    },
    {
      number: " 134",
      name: " LECROQ Jérémy",
      firstname: "Jérémy",
      lastname: "LECROQ",
      team: " B&B Hotels p/b KTM",
    },
    {
      number: " 133",
      name: " DEBUSSCHERE Jens",
      firstname: "Jens",
      lastname: "DEBUSSCHERE",
      team: " B&B Hotels p/b KTM",
    },
    {
      number: " 132",
      name: " BARTHE Cyril",
      firstname: "Cyril",
      lastname: "BARTHE",
      team: " B&B Hotels p/b KTM",
    },
    {
      number: " 131",
      name: " COQUARD Bryan",
      firstname: "Bryan",
      lastname: "COQUARD",
      team: " B&B Hotels p/b KTM",
    },
    {
      number: " 127",
      name: " NIZZOLO Giacomo",
      firstname: "Giacomo",
      lastname: "NIZZOLO",
      team: " Team Qhubeka ASSOS",
    },
    {
      number: " 126",
      name: " WIŚNIOWSKI Łukasz",
      firstname: "Łukasz",
      lastname: "WIŚNIOWSKI",
      team: " Team Qhubeka ASSOS",
    },
    {
      number: " 125",
      name: " PELUCCHI Matteo",
      firstname: "Matteo",
      lastname: "PELUCCHI",
      team: " Team Qhubeka ASSOS",
    },
    {
      number: " 124",
      name: " STOKBRO Andreas",
      firstname: "Andreas",
      lastname: "STOKBRO",
      team: " Team Qhubeka ASSOS",
    },
    {
      number: " 123",
      name: " GOGL Michael",
      firstname: "Michael",
      lastname: "GOGL",
      team: " Team Qhubeka ASSOS",
    },
    {
      number: " 122",
      name: " CLARKE Simon",
      firstname: "Simon",
      lastname: "CLARKE",
      team: " Team Qhubeka ASSOS",
    },
    {
      number: " 121",
      name: " BENNETT Sean",
      firstname: "Sean",
      lastname: "BENNETT",
      team: " Team Qhubeka ASSOS",
    },
    {
      number: " 117",
      name: " VAN POPPEL Danny",
      firstname: "Danny",
      lastname: "VAN POPPEL",
      team: " Intermarché - Wanty - Gobert Matériaux",
    },
    {
      number: " 116",
      name: " TAARAMÄE Rein",
      firstname: "Rein",
      lastname: "TAARAMÄE",
      team: " Intermarché - Wanty - Gobert Matériaux",
    },
    {
      number: " 115",
      name: " VAN POPPEL Boy",
      firstname: "Boy",
      lastname: "VAN POPPEL",
      team: " Intermarché - Wanty - Gobert Matériaux",
    },
    {
      number: " 114",
      name: " EIKING Odd Christian",
      firstname: "Odd Christian",
      lastname: "EIKING",
      team: " Intermarché - Wanty - Gobert Matériaux",
    },
    {
      number: " 113",
      name: " DEVRIENDT Tom",
      firstname: "Tom",
      lastname: "DEVRIENDT",
      team: " Intermarché - Wanty - Gobert Matériaux",
    },
    {
      number: " 112",
      name: " DELACROIX Theo",
      firstname: "Theo",
      lastname: "DELACROIX",
      team: " Intermarché - Wanty - Gobert Matériaux",
    },
    {
      number: " 111",
      name: " KOCH Jonas",
      firstname: "Jonas",
      lastname: "KOCH",
      team: " Intermarché - Wanty - Gobert Matériaux",
    },
    {
      number: " 107",
      name: " VANBILSEN Kenneth",
      firstname: "Kenneth",
      lastname: "VANBILSEN",
      team: " Cofidis, Solutions Crédits",
    },
    {
      number: " 106",
      name: " PEREZ Anthony",
      firstname: "Anthony",
      lastname: "PEREZ",
      team: " Cofidis, Solutions Crédits",
    },
    {
      number: " 105",
      name: " MORIN Emmanuel",
      firstname: "Emmanuel",
      lastname: "MORIN",
      team: " Cofidis, Solutions Crédits",
    },
    {
      number: " 104",
      name: " BOHLI Tom",
      firstname: "Tom",
      lastname: "BOHLI",
      team: " Cofidis, Solutions Crédits",
    },
    {
      number: " 103",
      name: " ALLEGAERT Piet",
      firstname: "Piet",
      lastname: "ALLEGAERT",
      team: " Cofidis, Solutions Crédits",
    },
    {
      number: " 102",
      name: " DRUCKER Jempy",
      firstname: "Jempy",
      lastname: "DRUCKER",
      team: " Cofidis, Solutions Crédits",
    },
    {
      number: " 101",
      name: " LAPORTE Christophe",
      firstname: "Christophe",
      lastname: "LAPORTE",
      team: " Cofidis, Solutions Crédits",
    },
    {
      number: " 97",
      name: " VANMARCKE Sep",
      firstname: "Sep",
      lastname: "VANMARCKE",
      team: " Israel Start-Up Nation",
    },
    {
      number: " 96",
      name: " WÜRTZ SCHMIDT Mads",
      firstname: "Mads",
      lastname: "WÜRTZ SCHMIDT",
      team: " Israel Start-Up Nation",
    },
    {
      number: " 95",
      name: " RENARD Alexis",
      firstname: "Alexis",
      lastname: "RENARD",
      team: " Israel Start-Up Nation",
    },
    {
      number: " 94",
      name: " HERMANS Ben",
      firstname: "Ben",
      lastname: "HERMANS",
      team: " Israel Start-Up Nation",
    },
    {
      number: " 93",
      name: " BOIVIN Guillaume",
      firstname: "Guillaume",
      lastname: "BOIVIN",
      team: " Israel Start-Up Nation",
    },
    {
      number: " 92",
      name: " BIERMANS Jenthe",
      firstname: "Jenthe",
      lastname: "BIERMANS",
      team: " Israel Start-Up Nation",
    },
    {
      number: " 91",
      name: " BARBIER Rudy",
      firstname: "Rudy",
      lastname: "BARBIER",
      team: " Israel Start-Up Nation",
    },
    {
      number: " 87",
      name: " SWIFT Connor",
      firstname: "Connor",
      lastname: "SWIFT",
      team: " Team Arkéa Samsic",
    },
    {
      number: " 86",
      name: " LOUVEL Matis",
      firstname: "Matis",
      lastname: "LOUVEL",
      team: " Team Arkéa Samsic",
    },
    {
      number: " 85",
      firstname: "Clément",
      lastname: "RUSSO",
      name: " RUSSO Clément",
      team: " Team Arkéa Samsic",
    },
    {
      number: " 84",
      name: " OWSIAN Łukasz",
      firstname: "Łukasz",
      lastname: "OWSIAN",
      team: " Team Arkéa Samsic",
    },
    {
      number: " 83",
      name: " MCLAY Daniel",
      firstname: "Daniel",
      lastname: "MCLAY",
      team: " Team Arkéa Samsic",
    },
    {
      number: " 82",
      name: " WELTEN Bram",
      firstname: "Bram",
      lastname: "WELTEN",
      team: " Team Arkéa Samsic",
    },
    {
      number: " 81",
      name: " BOUHANNI Nacer",
      firstname: "Nacer",
      lastname: "BOUHANNI",
      team: " Team Arkéa Samsic",
    },
    {
      number: " 77",
      name: " WANDAHL Frederik",
      firstname: "Frederik",
      lastname: "WANDAHL",
      team: " BORA - hansgrohe",
    },
    {
      number: " 76",
      name: " GAMPER Patrick",
      firstname: "Patrick",
      lastname: "GAMPER",
      team: " BORA - hansgrohe",
    },
    {
      number: " 75",
      name: " PÖSTLBERGER Lukas",
      firstname: "Lukas",
      lastname: "PÖSTLBERGER",
      team: " BORA - hansgrohe",
    },
    {
      number: " 74",
      name: " ACKERMANN Pascal",
      firstname: "Pascal",
      lastname: "ACKERMANN",
      team: " BORA - hansgrohe",
    },
    {
      number: " 73",
      name: " MEEUS Jordi",
      firstname: "Jordi",
      lastname: "MEEUS",
      team: " BORA - hansgrohe",
    },
    {
      number: " 72",
      name: " POLITT Nils",
      firstname: "Nils",
      lastname: "POLITT",
      team: " BORA - hansgrohe",
    },
    {
      number: " 71",
      name: " GROßSCHARTNER Felix",
      firstname: "Felix",
      lastname: "GROßSCHARTNER",
      team: " BORA - hansgrohe",
    },
    {
      number: " 67",
      name: " VAN MOER Brent",
      firstname: "Brent",
      lastname: "VAN MOER",
      team: " Lotto Soudal",
    },
    {
      number: " 66",
      name: " THIJSSEN Gerben",
      firstname: "Gerben",
      lastname: "THIJSSEN",
      team: " Lotto Soudal",
    },
    {
      number: " 65",
      name: " OLDANI Stefano",
      firstname: "Stefano",
      lastname: "OLDANI",
      team: " Lotto Soudal",
    },
    {
      number: " 64",
      name: " KRON Andreas",
      firstname: "Andreas",
      lastname: "KRON",
      team: " Lotto Soudal",
    },
    {
      number: " 63",
      name: " WELLENS Tim",
      firstname: "Tim",
      lastname: "WELLENS",
      team: " Lotto Soudal",
    },
    {
      number: " 62",
      name: " GILBERT Philippe",
      firstname: "Philippe",
      lastname: "GILBERT",
      team: " Lotto Soudal",
    },
    {
      number: " 61",
      name: " DEGENKOLB John",
      firstname: "John",
      lastname: "DEGENKOLB",
      team: " Lotto Soudal",
    },
    {
      number: " 57",
      name: " TURGIS Anthony",
      firstname: "Anthony",
      lastname: "TURGIS",
      team: " Team Total Direct Energie",
    },
    {
      number: " 56",
      name: " TERPSTRA Niki",
      firstname: "Niki",
      lastname: "TERPSTRA",
      team: " Team Total Direct Energie",
    },
    {
      number: " 55",
      name: " PETIT Adrien",
      firstname: "Adrien",
      lastname: "PETIT",
      team: " Team Total Direct Energie",
    },
    {
      number: " 54",
      name: " LAWLESS Chris",
      firstname: "Chris",
      lastname: "LAWLESS",
      team: " Team Total Direct Energie",
    },
    {
      number: " 53",
      name: " LATOUR Pierre",
      firstname: "Pierre",
      lastname: "LATOUR",
      team: " Team Total Direct Energie",
    },
    {
      number: " 52",
      name: " SOUPE Geoffrey",
      firstname: "Geoffrey",
      lastname: "SOUPE",
      team: " Team Total Direct Energie",
    },
    {
      number: " 51",
      name: " BOASSON HAGEN Edvald",
      firstname: "Edvald",
      lastname: "BOASSON HAGEN",
      team: " Team Total Direct Energie",
    },
    {
      number: " 47",
      name: " VAN DEN BERG Julius",
      firstname: "Julius",
      lastname: "VAN DEN BERG",
      team: " EF Education - Nippo",
    },
    {
      number: " 46",
      name: " VALGREN Michael",
      firstname: "Michael",
      lastname: "VALGREN",
      team: " EF Education - Nippo",
    },
    {
      number: " 45",
      name: " URÁN Rigoberto",
      firstname: "Rigoberto",
      lastname: "URÁN",
      team: " EF Education - Nippo",
    },
    {
      number: " 44",
      firstname: "Jonas",
      lastname: "RUTSCH",
      team: " EF Education - Nippo",
    },
    {
      number: " 43",
      firstname: "Sebastian",
      lastname: "LANGEVELD",
      team: " EF Education - Nippo",
    },
    {
      number: " 42",
      firstname: "Jens",
      lastname: "KEUKELEIRE",
      team: " EF Education - Nippo",
    },
    {
      number: " 41",
      firstname: "Alberto",
      lastname: "BETTIOL",
      team: " EF Education - Nippo",
    },
    {
      number: " 37",
      firstname: "Romain",
      lastname: "SEIGLE",
      team: " Groupama - FDJ",
    },
    {
      number: " 36",
      firstname: "Lars",
      lastname: "VAN DEN BERG",
      team: " Groupama - FDJ",
    },
    {
      number: " 35",
      firstname: "Benjamin",
      lastname: "THOMAS",
      team: " Groupama - FDJ",
    },
    {
      number: " 34",
      name: " STEWART Jake",
      firstname: "",
      lastname: "",
      team: " Groupama - FDJ",
    },
    {
      number: " 33",
      firstname: "William",
      lastname: "BONNET",
      team: " Groupama - FDJ",
    },
    {
      number: " 32",
      firstname: "Fabian",
      lastname: "LIENHARD",
      team: " Groupama - FDJ",
    },
    {
      number: " 31",
      firstname: "Alexys",
      lastname: "BRUNEL",
      team: " Groupama - FDJ",
    },
    {
      number: " 27",
      firstname: "Edward",
      lastname: "THEUNS",
      team: " Trek - Segafredo",
    },
    {
      number: " 26",
      firstname: "Mads",
      lastname: "PEDERSEN",
      team: " Trek - Segafredo",
    },
    {
      number: " 25",
      firstname: "Mattias",
      lastname: "SKJELMOSE JENSEN",
      team: " Trek - Segafredo",
    },
    {
      number: " 24",
      firstname: "Bauke",
      lastname: "MOLLEMA",
      team: " Trek - Segafredo",
    },
    {
      number: " 23",
      firstname: "Vincenzo",
      lastname: "NIBALI",
      team: " Trek - Segafredo",
    },
    {
      number: " 22",
      name: " MULLEN Ryan",
      firstname: "",
      lastname: "",
      team: " Trek - Segafredo",
    },
    {
      number: " 21",
      name: " KIRSCH Alex",
      firstname: "",
      lastname: "",
      team: " Trek - Segafredo",
    },
    {
      number: " 17",
      firstname: "Salvatore",
      lastname: "PUCCIO",
      team: " INEOS Grenadiers",
    },
    {
      number: " 16",
      firstname: "Geraint",
      lastname: "THOMAS",
      team: " INEOS Grenadiers",
    },
    {
      number: " 15",
      firstname: "Michał",
      lastname: "KWIATKOWSKI",
      team: " INEOS Grenadiers",
    },
    {
      number: " 14",
      firstname: "Ethan",
      lastname: "HAYTER",
      team: " INEOS Grenadiers",
    },
    {
      number: " 13",
      firstname: "Filippo",
      lastname: "GANNA",
      team: " INEOS Grenadiers",
    },
    {
      number: " 12",
      firstname: "Owain",
      lastname: "DOULL",
      team: " INEOS Grenadiers",
    },
    {
      number: " 11",
      firstname: "Egan",
      lastname: "BERNAL",
      team: " INEOS Grenadiers",
    },
    {
      number: " 7",
      firstname: "Damien",
      lastname: "TOUZÉ",
      team: " AG2R Citroën Team",
    },
    {
      number: " 6",
      firstname: "Lawrence",
      lastname: "NAESEN",
      team: " AG2R Citroën Team",
    },
    {
      number: " 5",
      firstname: "Alexis",
      lastname: "GOUGEARD",
      team: " AG2R Citroën Team",
    },
    {
      number: " 4",
      firstname: "Julien",
      lastname: "DUVAL",
      team: " AG2R Citroën Team",
    },
    {
      number: " 3",
      firstname: "Marc",
      lastname: "SARREAU",
      team: " AG2R Citroën Team",
    },
    {
      number: " 2",
      firstname: "Oliver",
      lastname: "NAESEN",
      team: " AG2R Citroën Team",
    },
    {
      number: " 1",
      firstname: "Greg",
      lastname: "VAN AVERMAET",
      team: " AG2R Citroën Team",
    },
  ]

  const race = "Bessèges"

  return (
    <Racepage
      title="Étoile de Bessèges 2021"
      startlist={startlist}
      race={race}
    />
  )
}

export default Besseges2021
